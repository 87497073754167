/* @import "~antd/dist/reset.css"; */
/* @import "~antd/dist/antd.css"; */
@import 'antd/dist/antd.variable.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(255, 255, 255, 0.3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.site-layout-content {
  min-height: 550px;
  padding: 24px;
  background: #fff;
  /* display: flex; */
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
[data-theme="dark"] .site-layout-content {
  background: #141414;
}
.site-page-header {
  border: 1px solid rgb(235, 237, 240);
  background-color: white;
}
/*
@media (width>425px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
  }

  定义滚动条轨道 内阴影+圆角
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  定义滑块 内阴影+圆角
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(192, 192, 192);
  }
}
*/